import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Toast, ToastOptions, ToastType } from '../model/toasts';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private toasts$ = new Subject<Toast>();

  constructor() { }

  public sendToast(toast: Toast): void {
    this.toasts$.next(toast);
  }

  public getToasts(): Observable<Toast> {
    return this.toasts$.asObservable();
  }

  public sendInfo(msg: string, options?: ToastOptions): void {
    this.sendToast({ type: ToastType.INFO, message: msg, options: options });
  }

  public sendError(msg: string, options?: ToastOptions): void {
    this.sendToast({ type: ToastType.ERROR, message: msg, options: options });
  }

  public sendSuccess(msg: string, options?: ToastOptions): void {
    this.sendToast({ type: ToastType.SUCCESS, message: msg, options: options });
  }

  public sendWarning(msg: string, options?: ToastOptions): void {
    this.sendToast({ type: ToastType.WARNING, message: msg, options: options });
  }

  public sendMessage(msg: string, options?: ToastOptions): void {
    this.sendToast({ type: ToastType.NEUTRAL, message: msg, options: options });
  }
}
