
export interface Toast {
  type: ToastType;
  message: string;
  options?: ToastOptions;
}

export interface ToastOptions {
  duration?: number;
}

export enum ToastType {
  NEUTRAL = 'neutral',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info'
}
